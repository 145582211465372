<template>
  <div>
    <a-select
      v-if="type === 'phone'"
      v-model:value="country"
      :filter-option="filterOption"
      :size="size"
      show-search
      aria-autocomplete="off"
      autocomplete="off"
      @change="handleChange"
    >
      <a-select-option
        v-for="c in countries"
        :key="`${c.phoneCode}${c.name}`"
        :value="c.phoneCode"
        :filter-option="filterOption"
        :size="size"
        show-search
        aria-autocomplete="off"
        autocomplete="off"
        @change="handleChange"
      >
        {{ `(${c.phoneCode}) ${c.name}` }}
      </a-select-option>
    </a-select>
    <a-select
      v-if="type === 'country'"
      v-model:value="country"
      :filter-option="filterOption"
      :size="size"
      show-search
      aria-autocomplete="off"
      autocomplete="off"
      @change="handleChange"
    >
      <a-select-option v-for="c in countries" :key="c.name" :value="c.iso2">
        {{ c.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { getJapanOnly, getCountriesPh } from '@/plugins/countriesStatesHelper';

export default {
  name: 'InputCountry',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    type: {
      type: String,
      default() {
        return 'country';
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      country: '',
    };
  },
  computed: {
    countries() {
      return this.type === 'phone' ? getCountriesPh() : getJapanOnly();
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.country = nv;
      },
    },
  },
  methods: {
    filterOption(input, option) {
      return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleChange() {
      this.$emit('change', this.country);
      this.$emit('update:value', this.country);
    },
  },
};
</script>

<style scoped></style>
