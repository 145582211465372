<template>
  <div
    class="search-form"
    :class="{ full: !showResultPanel }"
  >
    <a-form
      ref="form"
      class="columns is-mini-gap is-multiline"
    >
      <a-form-item
        :class="{ hasError: error }"
        class="column"
        :label="$t('Address')"
        name="address"
      >
        <InputAddress
          v-model:value="form.address"
          :size="size"
          @change="handleAddressChanged"
        />
        <div
          v-if="error"
          class="has-text-danger is-size-8"
          style="line-height: 1.4;"
        >
          {{ error }}
        </div>
      </a-form-item>
      <a-form-item
        class="column is-narrow"
        :label="$t('Country')"
      >
        <InputCountry
          v-model:value="form.country"
          :size="size"
          style="width: 150px;"
          @change="handleCountryChange"
        />
      </a-form-item>
      <div class="column is-12 is-paddingless" />
      <a-form-item
        class="column is-4"
        :label="$t('Language')"
      >
        <InputLanguage
          v-model:value-model="form.language"
          :size="size"
          style="width: 100%"
          @change="handleLangChange"
        />
      </a-form-item>
      <a-form-item
        class="column is-4"
        :label="$t('Sort by')"
      >
        <a-select
          v-model:value="form.sort"
          :size="size"
          style="width: 100%"
        >
          <a-select-option
            v-for="(o) in sortOptions"
            :key="o.value"
            :value="o.value"
          >
            {{ $t(o.label) }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        class="column is-4"
        :label="$t('Number of results')"
      >
        <a-select
          v-model:value="form.size"
          :size="size"
          style="width: 100%"
        >
          <a-select-option
            v-for="(o) in sizeOptions"
            :key="o.value"
            :value="o.value"
          >
            {{ o.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <div class="column is-12">
        <a-button
          :disabled="!form.coordinates"
          :loading="isFetching"
          :size="size"
          class="m-r-3x"
          style="min-width: 110px;"
          type="primary"
          @click="handleSearch"
        >
          {{ $t('Search') }}
        </a-button>
        <transition name="view-fade">
          <a-button
            v-if="!isFetching && results"
            class="m-r-3x"
            style="min-width: 110px;"
            :size="size"
            type="primary"
            @click="generatePdf"
          >
            {{ $t('Generate PDF') }}
          </a-button>
        </transition>
        <transition name="view-fade">
          <a-button
            v-if="form.coordinates"
            :size="size"
            style="min-width: 110px;"
            type="default"
            @click="handleClear"
          >
            {{ $t('Clear') }}
          </a-button>
        </transition>
      </div>
    </a-form>
  </div>
</template>

<i18n src="@/locales/components/simulations.json"></i18n>

<i18n>
{
  "en": {
    "Country": "Country",
    "Language": "Language",
    "Sort by": "Sort by",
    "Number of results": "Number of results",
    "Search": "Search",
    "Clear": "Clear",
    "Distance": "Distance",
    "Rating": "Rating",
    "Generate PDF": "Generate PDF"
  },
  "ja": {
    "Country": "国",
    "Language": "言語",
    "Sort by": "ソート",
    "Number of results": "結果数",
    "Search": "サーチ",
    "Clear": "クリア",
    "Distance": "距離",
    "Rating": "評価",
    "Generate PDF": "生成PDF"
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import { getLocale } from '@/utils/locale';
import { findCountry } from '@/components/helper';
import InputCountry from '@/components/InputCountry';
import InputLanguage from '@/components/InputLanguage';
import { pdf } from '@/views/simulations/mixins';
import InputAddress from '@/views/simulations/components/InputAddress';
import { DEFAULT_COUNTRY, DEFAULT_SORT, DEFAULT_SIZE } from '@/config/simulations';

export default {
  name: 'SearchForm',
  components: {
    InputAddress,
    InputCountry,
    InputLanguage,
  },
  mixins: [pdf],
  props: {
    address: {
      type: Object,
      default() { return null; },
    },
    isMapReady: {
      type: Boolean,
    },
  },
  emits: ['address-change'],
  data() {
    return {
      size: 'large',
      error: '',
      form: {
        address: undefined,
        country: undefined,
        placeId: undefined,
        coordinates: undefined,
        size: undefined,
        sort: undefined,
        language: undefined,
      },
      sortOptions: [
        { label: 'Distance', value: 'distance' },
        { label: 'Rating', value: 'rating' },
      ],
      sizeOptions: [
        { label: '1 - 30', value: 30 },
        { label: '1 - 60', value: 60 },
        { label: '1 - 100', value: 100 },
      ],
    };
  },
  computed: {
    ...mapState('simulation-search', ['isFetching', 'results', 'showResultPanel']),
  },
  watch: {
    address: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv) {
          ['address', 'coordinates', 'placeId'].forEach((key) => {
            this.form[key] = nv[key] || null;
          });
        }
      },
    },
    isMapReady: {
      immediate: true,
      handler(nv) {
        if (nv === true && this.$mq !== 'mobile') {
          this.defaultForm();
          setTimeout(this.onloadSearch, 300);
        }
      },
    },
  },
  methods: {
    async handleSearch() {
      try {
        const payload = this.form;

        if (!payload.placeId || !payload.coordinates) {
          this.error = this.$t('invalid-address');

          return;
        }

        await this.setRouteQueries();
        await this.$store.dispatch('simulation-search/search', payload);
      } catch (e) {
        console.log(e);
      }
    },
    async onloadSearch() {
      try {
        const payload = this.form;

        if (!payload.placeId || !payload.coordinates) {
          return;
        }

        await this.$store.dispatch('simulation-search/search', payload);
      } catch (e) {
        console.log(e);
      }
    },
    defaultForm() {
      this.form.address = this.$route.query.address || '';
      this.form.language = this.$route.query.language || getLocale();
      this.form.coordinates = this.$route.query.coordinates || '';
      this.form.placeId = this.$route.query.placeId || undefined;
      this.form.country = this.$route.query.country || DEFAULT_COUNTRY;
      this.form.sort = this.$route.query.sort || DEFAULT_SORT;
      this.form.size = this.$route.query.size || DEFAULT_SIZE;
    },
    setRouteQueries() {
      return this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          language: this.form.language,
          coordinates: this.form.coordinates,
          address: this.form.address,
          country: this.form.country,
          placeId: this.form.placeId,
          sort: this.form.sort,
          size: this.form.size,
        },
      });
    },
    handleCountryChange(nv) {
      const country = findCountry(nv);

      this.handleAddressChanged({
        address: '',
        coordinates: country.latlng.join(','),
        placeId: undefined,
      });
    },
    async handleLangChange(nv) {
      this.form.language = nv;
      await this.setRouteQueries();

      window.location.reload();
    },
    handleAddressChanged(data) {
      this.$emit('address-change', data);
    },
    async handleClear() {
      this.$store.dispatch('simulation-search/cancel');
      this.defaultForm();
      this.handleAddressChanged();

      this.$store.commit('simulation-search/RESET_SIMULATION');

      await this.$router.push({ name: this.$route.name });
    },
    generatePdf() {
      // force english for now
      const locale = 'en' || getLocale();
      const { coordinates, ...form } = this.form;
      const coordinatesArr = coordinates ? coordinates.split(',') : [];
      const payload = {
        simulation: {
          locale,
          lat: coordinatesArr[0],
          lng: coordinatesArr[1],
          apps: 'google,rakuten',
          address: form.address,
          language: form.language,
          country: form.country,
          placeId: form.placeId,
          sort: form.sort,
          size: form.size,
        },
      };

      this.downloadPdf(payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-form {
  position: absolute;
  top: $bleed*2;
  right: $bleed*2;
  left: $bleed*2;
  z-index: 100;
  background: $white;
  padding: $bleed*3;

  &.full {
    width: 50%;
  }
}

.hasError ::v-deep input {
  border-color: $danger;
}
</style>
