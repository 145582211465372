{
  "en": {
    "Simulations": "Simulations",
    "Simulation Calculation": "Simulation Calculation",
    "Request ID": "Request ID",
    "Version ID": "Version ID",
    "Postal code": "Postal code",
    "Address": "Address",
    "simulation-exists": "A request ID with this version already exist",
    "Request ID is required": "Request ID is required",
    "Version ID is required": "Version ID is required",
    "address-required": "Address is required",
    "invalid-address": "Please select a formatted address from the auto suggest drop-down"
  },
  "ja": {
    "Simulations": "シミュレーション",
    "Simulation Calculation": "Simulation Calculation",
    "Request ID": "Request ID",
    "Version ID": "Version ID",
    "Postal code": "郵便番号",
    "Address": "アドレス",
    "simulation-exists": "A request ID with this version already exist",
    "Request ID is required": "Request ID is required",
    "Version ID is required": "Version ID is required",
    "address-required": "アドレスが必要です",
    "invalid-address": "自動プロジェクタードロップダウンからフォーマットされたアドレスを選択してください"
  }
}
