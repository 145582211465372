<template>
  <div>
    <SearchMap
      :request="form"
      :is-map-ready="isMapReady"
      @address-update="handleMapAddressUpdate"
    >
      <template #form>
        <Form
          :is-map-ready="isMapReady"
          :address="form"
          @address-change="handleMapAddressUpdate"
        />
      </template>
    </SearchMap>
  </div>
</template>

<script>
import Form from '@/views/simulations/components/search/Form';
import SearchMap from '@/views/simulations/components/SearchMap';

export default {
  name: 'Search',
  components: {
    Form,
    SearchMap,
  },
  data() {
    return {
      isMapReady: false,
      form: {
        address: undefined,
        placeId: undefined,
        coordinates: undefined,
      },
    };
  },
  async created() {
    this.isMapReady = false;

    this.$store.commit('simulation-search/RESET_SIMULATION');
    this.form.address = this.$route.query.address || '';
    this.form.coordinates = this.$route.query.coordinates || '';
    this.form.placeId = this.$route.query.placeId || '';

    const lang = this.$route.query.language;
    await this.$store.dispatch('google-map/load', lang);

    this.isMapReady = true;
  },
  methods: {
    handleMapAddressUpdate(data) {
      if (!data) {
        this.form.address = '';
        this.form.placeId = undefined;
        this.form.coordinates = '';

        return;
      }

      this.form.address = data.address;
      this.form.placeId = data.placeId;
      this.form.coordinates = data.coordinates;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
